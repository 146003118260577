<template>
  <div
    class="widget-item"
    :class="{
      'link-widget': widget.type === 1,
      'banner-widget': widget.type === 41,
      'upcoming-widget': widget.type === 9,
      'poll-widget': widget.type === 43,
      'embed-widget': widget.type === 49,
    }"
  >
    <div v-show="isSorting" class="event-barrier"></div>
    <div class="info">
      <button v-if="draggable" class="handle handler-btn">
        <handler-icon fill-color="#9EA0A6"></handler-icon>
      </button>

      <ent-brand-input-file
        v-if="widget.type === 1"
        class="featured-img"
        button-padding="8px 8px"
        :accepts="featuredImageAccepts"
        :src="state.widgetForm.featuredImage"
        :width="110"
        :height="110"
        :delete-image="true"
        @openFIleSelector="actions.openFileStack()"
        @delete-image="actions.deleteImage()"
      ></ent-brand-input-file>

      <div v-if="widget.type === 1" class="meta-info">
        <input-basic-new
          class="input-basic title text-default"
          label="타이틀"
          :required="true"
          padding="8px"
          :default-value="state.widgetForm.title"
          @updateData="(value) => actions.updateTitle(value)"
        ></input-basic-new>
        <input-basic-new
          class="input-basic description text-gray-second"
          label="URL"
          :required="true"
          padding="8px"
          :default-value="state.widgetForm.url"
          @updateData="(value) => actions.updateUrl(value)"
        ></input-basic-new>
        <button-basic
          class="update-btn"
          text="수정"
          text-size="s2"
          padding="8px 16px"
          :disabled="!state.activeUpdateLinkWidget"
          @action="actions.editLinkWidget()"
        ></button-basic>
      </div>
      <div v-if="widget.type === 9" class="meta-info">
        <p class="title text-default">{{ widget.title }}</p>
        <p class="description text-gray-second">
          {{ widget.description }}
        </p>
        <input-basic-new
          class="input-basic title text-default"
          label="주제"
          placeholder="어떤 이벤트를 열고 싶으신가요?"
          padding="8px"
          :required="true"
          :default-value="state.upcomingWidgetForm.title"
          @updateData="(value) => actions.updateUpcomingTitle(value)"
        ></input-basic-new>
        <input-text-box-new
          :model-value="state.upcomingWidgetForm.description"
          label="설명"
          placeholder="이벤트에 대한 대략적인 구성을 소개해주시면 좋아요!"
          :height-resize="false"
          :rows="3"
          @update:modelValue="
            (value) => {
              actions.updateUpcomingDescription(value);
            }
          "
        ></input-text-box-new>
        <button-basic
          class="update-btn"
          text="수정"
          text-size="s2"
          padding="8px 16px"
          :disabled="!state.activeUpdateUpcomingWidget"
          @action="actions.editUpcomingWidget()"
        ></button-basic>
      </div>
      <div v-if="widget.type === 41" class="meta-info">
        <div class="banner-image-label sub-text-s2">PC 배너 이미지</div>
        <ent-brand-input-file-v2
          class="input-file-comp"
          button-padding="8px 8px"
          :accepts="featuredImageAccepts"
          :src="state.bannerWidgetForm.featuredImage"
          :delete-image="true"
          @open-file-selector="actions.openFileStack('banner-pc')"
          @delete-image="actions.deleteImage('banner-pc')"
        ></ent-brand-input-file-v2>
        <div class="banner-image-label sub-text-s2">모바일 배너 이미지</div>
        <ent-brand-input-file-v2
          class="input-file-comp"
          button-padding="8px 8px"
          :accepts="featuredImageAccepts"
          :src="state.bannerWidgetForm.mFeaturedImage"
          :delete-image="true"
          @open-file-selector="actions.openFileStack('banner-mo')"
          @delete-image="actions.deleteImage('banner-mo')"
        ></ent-brand-input-file-v2>
        <input-basic-new
          class="input-basic url text-gray-second"
          label="브랜드 URL"
          placeholder="연결하고자 하는 URL을 입력해 주세요."
          :required="true"
          padding="8px"
          :default-value="state.bannerWidgetForm.url"
          @updateData="(value) => actions.updateBannerUrl(value)"
        ></input-basic-new>
        <input-basic-new
          class="input-basic title text-default"
          label="대체 텍스트"
          placeholder="이미지가 노출되지 않을 경우 대체해서 노출 할 텍스트를 입력해주세요"
          padding="8px"
          :required="true"
          :default-value="state.bannerWidgetForm.title"
          @updateData="(value) => actions.updateBannerTitle(value)"
        ></input-basic-new>
        <input-text-box-new
          :model-value="state.bannerWidgetForm.description"
          label="배너 설명"
          placeholder="배너에 대한 간단한 설명 또는 메모를 입력해주세요"
          :height-resize="false"
          :rows="2"
          @update:modelValue="
            (value) => {
              actions.updateBannerDescription(value);
            }
          "
        ></input-text-box-new>
        <button-basic
          class="update-btn"
          text="수정"
          text-size="s2"
          padding="8px 16px"
          :disabled="!state.activeUpdateBannerWidget"
          @action="actions.editBannerWidget()"
        ></button-basic>
      </div>
      <div v-if="widget.type === 43" class="meta-info">
        <p class="title text-default">투표 열기</p>
        <p class="description text-gray-second">
          팔로워의 의견을 듣고 싶다면 투표기능을 사용해보세요 (투표는
          '투표관리'에서 만들 수 있어요)
        </p>
        <input-select-small
          v-model="state.pollWidgetForm.resourceId"
          label="연결할 투표 정보 선택하기"
          placeholder="투표 주제를 선택하세요"
          :required="true"
          :list="state.polls"
        ></input-select-small>
        <button-basic
          class="update-btn"
          text="수정"
          text-size="s2"
          padding="8px 16px"
          @action="actions.editPollWidget()"
        ></button-basic>
      </div>
      <div v-if="widget.type === 49" class="meta-info">
        <p class="title text-default">임베드 위젯</p>
        <input-basic-new
          class="input-basic title"
          label="타이틀"
          padding="8px"
          sub-label="임베드 위젯 타이틀을 입력해주세요."
          placeholder="비입력 시, 타이틀 영역이 노출되지 않습니다."
          :default-value="state.embedWidgetForm.title"
          @updateData="
            (value) => {
              actions.updateEmbedWidgetTitle(value);
            }
          "
        ></input-basic-new>
        <div>
          <input-text-box-new
            :model-value="state.embedWidgetForm.description"
            label="임베드 코드"
            sub-label="임베드 코드는 필수 입력 사항입니다."
            placeholder="임베드 위젯 코드를 입력해주세요."
            :required="true"
            :height-resize="false"
            :rows="5"
            @update:modelValue="
              (value) => {
                actions.updateEmbedWidgetDescription(value);
              }
            "
          ></input-text-box-new>
          <p
            v-if="state.embedErrorMessage"
            class="error sub-text-s3 text-red-50"
          >
            {{ state.embedErrorMessage }}
          </p>
        </div>
        <button-basic
          class="update-btn"
          text="수정"
          text-size="s2"
          padding="8px 16px"
          :disabled="!state.activeUpdateEmbedWidget"
          @action="actions.editEmbedWidget()"
        ></button-basic>
      </div>
      <div
        v-if="
          widget.type !== 1 &&
          widget.type !== 9 &&
          widget.type !== 41 &&
          widget.type !== 43 &&
          widget.type !== 49
        "
        class="meta-info"
      >
        <p class="title text-default">{{ widget.title }}</p>
        <p class="description text-gray-second">
          {{ widget.description }}
        </p>
      </div>
    </div>

    <div class="btn-wrapper">
      <input-switch v-model="state.isPublished"></input-switch>
      <button-basic
        class="delete-btn"
        bg-color="transparent"
        padding="8px"
        @action="actions.openWidgetDeleteModal()"
      >
        <template #icon>
          <delete-icon
            width="20"
            height="20"
            fill-color="#FF4646"
          ></delete-icon>
        </template>
      </button-basic>
    </div>
  </div>
</template>

<script>
// Todo refactoring 필요, css,js,html 중복요소가 너무 많음
import { computed, getCurrentInstance, onMounted, reactive, watch } from "vue";
import HandlerIcon from "@/components/console/icons/HandlerIcon.vue";
import DeleteIcon from "@/components/console/icons/DeleteIcon.vue";
import InputSwitch from "@/components/console/inputs/InputSwitch.vue";
import ButtonBasic from "@/components/console/buttons/ButtonBasic.vue";
import InputBasicNew from "@/components/console/inputs/InputBasicNew.vue";
import EntBrandInputFile from "@/pages/console/EntBrand/EntBrandInputFile/EntBrandInputFile.vue";
import FileStackService from "@/services/FileStackService";
import InputTextBoxNew from "@/components/console/inputs/InputTextBoxNew.vue";
import EntBrandInputFileV2 from "@/pages/console/EntBrand/EntBrandInputFileV2/EntBrandInputFileV2.vue";
import InputSelectSmall from "@/components/console/inputs/InputSelectSmall.vue";
import ApiService from "@/api";
import { transformKeyFormat } from "@/helper/brandPage";

export default {
  name: "WidgetItem",
  components: {
    InputSelectSmall,
    EntBrandInputFileV2,
    InputTextBoxNew,
    EntBrandInputFile,
    InputBasicNew,
    ButtonBasic,
    InputSwitch,
    DeleteIcon,
    HandlerIcon,
  },
  props: {
    widget: {
      type: Object,
      required: false,
    },
    draggable: {
      type: Boolean,
      default: false,
    },
    isSorting: {
      type: Boolean,
      default: false,
    },
  },
  emits: [
    "updatePublished",
    "openDeleteWidgetModal",
    "editLinkWidget",
    "editUpcomingWidget",
    "editBannerWidget",
    "editPollWidget",
  ],
  setup(props, { emit }) {
    const { proxy } = getCurrentInstance();
    const featuredImageAccepts = ["jpg", "jpeg", "png", "webp"];

    const state = reactive({
      isPublished: props.widget.isPublished,
      widgetForm: {
        type: 1,
        title: "",
        url: "",
        featuredImage: "",
        isPublished: computed(() => {
          return state.isPublished;
        }),
      },
      activeUpdateLinkWidget: computed(() => {
        return (
          state.widgetForm.title.trim().length > 0 &&
          state.widgetForm.url.trim().length > 0
        );
      }),
      upcomingWidgetForm: {
        type: 9,
        title: "",
        description: "",
        isPublished: computed(() => {
          return state.isPublished;
        }),
      },
      bannerWidgetForm: {
        type: 41,
        url: "",
        title: "",
        description: "",
        featuredImage: "",
        mFeaturedImage: "",
        isPublished: computed(() => {
          return state.isPublished;
        }),
      },
      pollWidgetForm: {
        type: 43,
        resourceId: "",
        isPublished: computed(() => {
          return state.isPublished;
        }),
      },
      embedWidgetForm: {
        type: 49,
        resourceId: "",
        isPublished: computed(() => {
          return state.isPublished;
        }),
        url: "",
        title: "",
        description: "",
      },
      activeUpdateUpcomingWidget: computed(() => {
        return state.upcomingWidgetForm.title.trim().length > 0;
      }),
      activeUpdateBannerWidget: computed(() => {
        return (
          state.bannerWidgetForm.url.trim().length > 0 &&
          state.bannerWidgetForm.title.trim().length > 0 &&
          state.bannerWidgetForm.featuredImage &&
          state.bannerWidgetForm.mFeaturedImage
        );
      }),
      activeUpdateEmbedWidget: computed(() => {
        return (
          state.embedWidgetForm.description.trim().length > 0 ||
          state.embedErrorMessage.length > 0
        );
      }),
      polls: [],
      embedErrorMessage: "",
    });

    watch(
      () => state.isPublished,
      (isPublished) => {
        emit("updatePublished", isPublished);
      }
    );

    onMounted(async () => {
      if (props.widget.type === 1) {
        state.widgetForm.title = props.widget.title;
        state.widgetForm.url = props.widget.url;
        state.widgetForm.featuredImage = props.widget.featuredImage;
      }
      if (props.widget.type === 9) {
        state.upcomingWidgetForm.title = props.widget.data.title;
        state.upcomingWidgetForm.description = props.widget.data.description;
      }
      if (props.widget.type === 41) {
        state.bannerWidgetForm.title = props.widget.title
          ? props.widget.title
          : "";
        state.bannerWidgetForm.url = props.widget.url ? props.widget.url : "";
        state.bannerWidgetForm.description = props.widget.description
          ? props.widget.description
          : "";
        state.bannerWidgetForm.featuredImage = props.widget.featuredImage;
        state.bannerWidgetForm.mFeaturedImage = props.widget.mFeaturedImage;
      }
      if (props.widget.type === 43) {
        state.pollWidgetForm.type = props.widget.type;
        state.pollWidgetForm.resourceId = props.widget.data?.resourceId;
        // note 투표 위젯이 공개 상태인데, 위젯에 연결한 투표가 긴급 종료 처리된 경우 비공개로 전환
        if (!state.pollWidgetForm.resourceId) {
          state.isPublished && (state.isPublished = false);
        }
        // todo 스토어로 api 구성하기
        // todo api 호출 어디서 할지 고민하기
        try {
          const res = await ApiService.getSpacesWidgetPolls();
          if (res.data.success) {
            let polls = res.data.data;
            state.polls = transformKeyFormat(polls);
            return;
          }
        } catch (e) {
          console.log(e);
          return false;
        }
      }
      if (props.widget.type === 49) {
        state.embedWidgetForm.type = props.widget.type;
        state.embedWidgetForm.resourceId = props.widget.data?.resourceId;
        state.embedWidgetForm.title = props.widget.title
          ? props.widget.title
          : "";
        state.embedWidgetForm.url = props.widget.url;
        console.log(props.widget.description);
        state.embedWidgetForm.description = props.widget.description;
      }
    });

    const isValidEmbedIframe = (inputString) => {
      // 1. src 값 유효성 검사
      const srcPattern = new RegExp(
        "^(https?:)?//(?:" +
          "www\\.youtube(?:-nocookie)?\\.com/embed/|" +
          "player\\.vimeo\\.com/video/|" +
          "open\\.spotify\\.com/embed/|" +
          "serviceapi\\.rmcnmv\\.naver\\.com/|" +
          "videofarm\\.daum\\.net/|" +
          "www\\.google\\.com/|" +
          "maps\\.google\\.com/|" +
          "play\\.afreeca\\.com/|" +
          "v\\.nate\\.com/|" +
          "www\\.microsoft\\.com/showcase/video\\.aspx/|" +
          "w\\.soundcloud\\.com/|" +
          "www\\.facebook\\.com/|" +
          "kakaotv\\.daum\\.net/|" +
          "v\\.afree\\.ca/|" +
          "play-tv\\.kakao\\.com/|" +
          "cdn\\.knightlab\\.com/" +
          "vote\\.bigc\\.im/embed/votes/[a-zA-Z0-9]+/|" +
          "vote\\.meec\\.dev/embed/votes/[a-zA-Z0-9]+/|" +
          ")"
      );

      // 2. 태그 검사
      const tagPattern =
        /<(iframe|embed|object|video|audio)[^>]+(src|data)="([^"]+)"[^>]*>/i;

      // 입력된 문자열에서 src 값을 추출하여 검사
      const tagMatch = inputString.match(tagPattern);
      if (!tagMatch) {
        return false; // 유효한 태그가 아님
      }

      const srcValue = tagMatch[3];
      if (!srcPattern.test(srcValue)) {
        return false; // 유효하지 않은 src 값
      }

      return true;
    };

    const actions = {
      openWidgetDeleteModal: () => {
        emit("openDeleteWidgetModal");
      },
      updateTitle: (value) => {
        state.widgetForm.title = value;
      },
      updateUrl: (value) => {
        state.widgetForm.url = value;
      },
      updateUpcomingTitle: (value) => {
        state.upcomingWidgetForm.title = value;
      },
      updateUpcomingDescription: (value) => {
        state.upcomingWidgetForm.description = value;
      },
      updateBannerUrl: (value) => {
        state.bannerWidgetForm.url = value;
      },
      updateBannerTitle: (value) => {
        state.bannerWidgetForm.title = value;
      },
      updateBannerDescription: (value) => {
        state.bannerWidgetForm.description = value;
      },
      updateEmbedWidgetTitle: (value) => {
        state.embedWidgetForm.title = value;
      },
      updateEmbedWidgetDescription: (value) => {
        state.embedWidgetForm.description = value;
      },
      editLinkWidget: () => {
        emit("editLinkWidget", state.widgetForm);
      },
      editUpcomingWidget: () => {
        emit("editUpcomingWidget", state.upcomingWidgetForm);
      },
      editBannerWidget: () => {
        emit("editBannerWidget", state.bannerWidgetForm);
      },
      editPollWidget: () => {
        emit("editPollWidget", state.pollWidgetForm);
      },
      editEmbedWidget: () => {
        if (isValidEmbedIframe(state.embedWidgetForm.description)) {
          state.embedErrorMessage = "";
        } else {
          state.embedErrorMessage = "유효하지 않은 코드입니다.";
          return;
        }
        emit("editEmbedWidget", state.embedWidgetForm);
      },
      openFileStack: (type = null) => {
        const mimeTypes = featuredImageAccepts.map((item) => {
          return proxy.$const.mimeTypes[item].value;
        });

        const fileStack = new FileStackService();
        if (type === null) {
          fileStack.options.transformations.crop.aspectRatio = 1 / 1;
        } else {
          fileStack.options.transformations.crop = false;
        }
        fileStack.options.transformations.circle = false;
        fileStack.options.accept = mimeTypes;

        fileStack.options.onFileUploadFinished = (fileMetaData) => {
          if (type === "banner-pc") {
            state.bannerWidgetForm.featuredImage = fileMetaData.url;
          } else if (type === "banner-mo") {
            state.bannerWidgetForm.mFeaturedImage = fileMetaData.url;
          } else {
            state.widgetForm.featuredImage = fileMetaData.url;
          }
        };

        fileStack.open(fileStack.options);
      },
      deleteImage: (type = null) => {
        if (type === "banner-pc") {
          state.bannerWidgetForm.featuredImage = "";
        } else if (type === "banner-mo") {
          state.bannerWidgetForm.mFeaturedImage = "";
        } else {
          state.widgetForm.featuredImage = "";
        }
      },
    };

    return { state, actions, featuredImageAccepts };
  },
};
</script>

<style src="./style.css" scoped></style>
