<template>
  <base-modal
    title="임베드 위젯 추가"
    @closeModal="state.loading ? '' : $emit('hideModal')"
  >
    <template #modalBody>
      <div class="input-wrapper">
        <input-basic
          label="타이틀"
          sub-label="임베드 위젯 타이틀을 입력해주세요."
          placeholder="비입력 시, 타이틀 영역이 노출되지 않습니다."
          :default-value="state.widgetForm.title"
          @updateData="
            (value) => {
              actions.updateTitle(value);
            }
          "
        ></input-basic>
        <div>
          <input-text-box
            label="임베드 코드"
            sub-label="임베드 코드는 필수 입력 사항입니다."
            placeholder="임베드 위젯 코드를 입력해주세요."
            :required="true"
            :rows="3"
            :height-resize="false"
            :border-none="false"
            :model-value="state.widgetForm.description"
            @update:modelValue="
              (value) => {
                actions.updateDescription(value);
              }
            "
          ></input-text-box>
          <p v-if="state.errorMessage" class="error sub-text-s3 text-red-50">
            {{ state.errorMessage }}
          </p>
        </div>
      </div>
      <div class="btn-wrapper">
        <button-basic
          class="cancel-btn"
          bg-color="#ECF1F4"
          color="#0D0D10"
          text="뒤로"
          @click="actions.openListModal()"
        ></button-basic>
        <button-basic
          class="save-btn"
          :disabled="!isActiveBtn || state.loading"
          text="추가"
          @click="actions.createWidget()"
        ></button-basic>
      </div>
    </template>
  </base-modal>
</template>

<script>
import { computed, reactive } from "vue";
import { useStore } from "vuex";
import InputBasic from "../../../../components/console/inputs/InputBasic";
import InputTextBox from "../../../../components/console/inputs/InputTextBox";
import swal from "../../../../helper/swal";
import BaseModal from "../../../../components/common/BaseModal.vue";
import ButtonBasic from "@/components/console/buttons/ButtonBasic.vue";

export default {
  name: "AddBrandEmbedWidgetModal",
  components: {
    ButtonBasic,
    InputBasic,
    InputTextBox,
    BaseModal,
  },
  emits: ["createWidget", "hideModal", "openWidgetListModal"],
  setup(props, { emit }) {
    const store = useStore();
    const state = reactive({
      widgetForm: {
        type: 49,
        title: "",
        description: "",
      },
      loading: false,
      errorMessage: "",
    });

    const isActiveBtn = computed(() => {
      return state.widgetForm.description.trim().length > 0;
    });

    const isValidEmbedIframe = (inputString) => {
      // 1. src 값 유효성 검사
      const srcPattern = new RegExp(
        "^(https?:)?//(?:" +
          "www\\.youtube(?:-nocookie)?\\.com/embed/|" +
          "player\\.vimeo\\.com/video/|" +
          "open\\.spotify\\.com/embed/|" +
          "serviceapi\\.rmcnmv\\.naver\\.com/|" +
          "videofarm\\.daum\\.net/|" +
          "www\\.google\\.com/|" +
          "maps\\.google\\.com/|" +
          "play\\.afreeca\\.com/|" +
          "v\\.nate\\.com/|" +
          "www\\.microsoft\\.com/showcase/video\\.aspx/|" +
          "w\\.soundcloud\\.com/|" +
          "www\\.facebook\\.com/|" +
          "kakaotv\\.daum\\.net/|" +
          "v\\.afree\\.ca/|" +
          "play-tv\\.kakao\\.com/|" +
          "cdn\\.knightlab\\.com/" +
          "vote\\.bigc\\.im/embed/votes/[a-zA-Z0-9]+/|" +
          "vote\\.meec\\.dev/embed/votes/[a-zA-Z0-9]+/|" +
          ")"
      );

      // 2. 태그 검사
      const tagPattern =
        /<(iframe|embed|object|video|audio)[^>]+(src|data)="([^"]+)"[^>]*>/i;

      // 입력된 문자열에서 src 값을 추출하여 검사
      const tagMatch = inputString.match(tagPattern);
      if (!tagMatch) {
        return false; // 유효한 태그가 아님
      }

      const srcValue = tagMatch[3];
      if (!srcPattern.test(srcValue)) {
        return false; // 유효하지 않은 src 값
      }

      return true;
    };

    const actions = {
      updateTitle: (data) => {
        state.widgetForm.title = data;
      },
      updateDescription: (data) => {
        state.widgetForm.description = data;
      },
      createWidget: () => {
        state.loading = true;
        if (isValidEmbedIframe(state.widgetForm.description)) {
          state.errorMessage = "";
        } else {
          state.errorMessage = "유효하지 않은 코드입니다.";
          state.loading = false;
          return false;
        }
        let formData = new FormData();

        formData.append("type", state.widgetForm.type);
        formData.append("title", state.widgetForm.title);
        formData.append("description", state.widgetForm.description);

        store.dispatch("space/postSpaceWidget", { data: formData }).then(() => {
          swal.createCompleteToast();
          state.loading = false;
          emit("saveComplete");
        });
      },
      closeModal: () => {
        if (!state.loading) {
          emit("hideModal");
        }
      },
      openListModal: () => {
        emit("openWidgetListModal");
      },
    };

    return { state, actions, isActiveBtn };
  },
};
</script>

<style src="./style.css" scoped></style>
